import React from 'react';
import { approvalReq } from 'components/Dashboard/Content/Recognitions/Configuration/constants';
import DropdownMenu from 'components/Dashboard/Content/Recognitions/Configuration/RecognitionConfiguration/DropdownMenu';
import PropTypes from 'prop-types';
import './style.scss';
import { ACCESS_FOR } from 'components/Dashboard/Content/Recognitions/Configuration/RecognitionConfiguration/constants';

const Index = ({
    index,
    recognitionTypeData,
    rewardName,
    recogniser,
    points,
    isApprovalRequired,
    nameForPoints = 'Points',
    handleAction,
    removeDeleteOption,
}) => {
    const formatRecognisers = (recognisers) => {
        if (recogniser.length === 1 && recogniser[0] === ACCESS_FOR.USER) {
            return ACCESS_FOR.ALL_USERS;
        }
        const formattedRecognisers = recognisers
            .map((recogniser) => {
                const formattedRecogniser = recogniser + 's';
                return formattedRecogniser;
            })
            .join(' and ');

        return formattedRecognisers.charAt(0).toUpperCase() + formattedRecognisers.slice(1);
    };
    const isOnlyAdminsSelected = recogniser.length === 1 && recogniser[0] === ACCESS_FOR.ADMIN;

    return (
        <div className='reward-input'>
            <div className='reward-container'>
                <p className='reward-name'>{rewardName}</p>
                <p className='reward-for'>
                    For <b className='reward-recogniser'>{formatRecognisers(recogniser)}</b>
                </p>
            </div>
            <div className='reward-input-container'>
                <div className='name-for-reward'>
                    <b className='cr-points'>{points} &nbsp;</b>
                    <p className='cr-points-name'>{nameForPoints}</p>
                </div>
            </div>
            {!isOnlyAdminsSelected && recognitionTypeData.isApprovalRequired ? (
                <div className='container-approval '>
                    <p>{approvalReq}</p>
                </div>
            ) : (
                <div className='container-approval'></div>
            )}
            <div className='cr-dropdown'>
                <DropdownMenu
                    handleAction={handleAction}
                    recognitionTypeData={recognitionTypeData}
                    removeDeleteOption={removeDeleteOption}
                />
            </div>
        </div>
    );
};

Index.propTypes = {
    index: PropTypes.number,
    recognitionTypeData: PropTypes.object,
    rewardName: PropTypes.string,
    recogniser: PropTypes.array,
    points: PropTypes.number,
    isApprovalRequired: PropTypes.bool,
    nameForPoints: PropTypes.string,
    handleAction: PropTypes.func,
    removeDeleteOption: PropTypes.bool,
};

export default Index;
