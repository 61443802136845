import React, { useState } from 'react';
import TitleBar from 'components/ReusableComponents/TitleBar';
import ConfigurationItem from './Components/ConfigurationItem';
import { getSuggestionBoxConfigs, nonBooleankeys } from '../constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GET_SUGGESTION_BOX_CONFIGURATION } from 'Services/apiKeys';
import { getSuggestionBoxConfiguration, updateSuggestionBoxConfiguration } from '../Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

const Index = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const { platform, suggestionBoxConfigs, todoConfigs } = useSelector(mapStateToProps, shallowEqual);
    const [suggestionBoxConfiguration, setSuggestionBoxConfiguration] = useState(getSuggestionBoxConfigs());

    useQuery([GET_SUGGESTION_BOX_CONFIGURATION], getSuggestionBoxConfiguration, {
        onSuccess: (data) => {
            const config = data?.data;
            // if suggestionBox is not setup, there wont be default config values like enabled in api response
            if (config?.hasOwnProperty('enabled')) {
                if (!todoConfigs.isSuggestionBoxFlowCompleted) {
                    updateWorkspaceDetails(dispatch, { todo: { ...todoConfigs, isSuggestionBoxFlowCompleted: true } });
                }
                updateWorkspaceDetails(dispatch, { suggestionBox: { ...config } });
                setSuggestionBoxConfiguration(
                    getSuggestionBoxConfigs(
                        config,
                        config?.enabled,
                        config?.allowAnonymous,
                        config?.postPublicly,
                        config?.approvalRequired,
                        config?.allowVote
                    )
                );
            }
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    const { mutateAsync: updateSuggestionBoxConfigs } = useMutation(updateSuggestionBoxConfiguration);

    const toggleConfiguration = async (index, key, value) => {
        const updatedConfiguration = [...suggestionBoxConfiguration];
        if (!nonBooleankeys.includes(key)) {
            updatedConfiguration[index] = {
                ...updatedConfiguration[index],
                isEnabled: !updatedConfiguration[index].isEnabled,
            };
        } else {
            updatedConfiguration[index] = {
                ...updatedConfiguration[index],
                [key]: value,
            };
        }
        setSuggestionBoxConfiguration(updatedConfiguration);
        if (key === 'admin') {
            value = value._id;
        }
        if (key === 'postPublicly' && !updatedConfiguration[index].suggestionChannel?.name) {
            return;
        }
        updateConfigs({ key, value });
    };
    const updateConfigs = async ({ key, value }) => {
        try {
            const apiData = {
                [key]: value,
            };
            if (key === 'suggestionChannel') {
                apiData['postPublicly'] = true;
            }
            await updateSuggestionBoxConfigs({ apiData });
            if (!todoConfigs.isSuggestionBoxFlowCompleted) {
                updateWorkspaceDetails(dispatch, { todo: { ...todoConfigs, isSuggestionBoxFlowCompleted: true } });
            }
            updateWorkspaceDetails(dispatch, {
                suggestionBox: {
                    ...suggestionBoxConfigs,
                    [key]: value,
                    ...(key === 'suggestionChannel' ? { postPublicly: true } : {}),
                },
            });
            showSnackBarMessage(SetSnackbar, 'success', 'Updated successfully');
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
            // Invalidate the data in case the api fails
            queryClient.invalidateQueries(GET_SUGGESTION_BOX_CONFIGURATION);
        }
    };
    return (
        <div>
            <TitleBar title='Configuration' />
            {suggestionBoxConfiguration?.map((option, index) => (
                <ConfigurationItem
                    option={option}
                    index={index}
                    toggleConfiguration={toggleConfiguration}
                    key={option?.heading}
                    platform={platform}
                />
            ))}
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    suggestionBoxConfigs: Workspace.suggestionBox,
    todoConfigs: Workspace.todo,
    platform: Workspace.platform,
});

export default Index;
