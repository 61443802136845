import React from 'react';
import { AdminBox } from 'utils/Stylesheet/style.js';
import PropTypes from 'prop-types';
import { getIntegratedChannelName } from 'utils/HelperFunctions';
import './style.scss';

const Index = ({ data, title, platform }) => {
    const handleConditionalrendering = () => {
        if (title === 'admin') {
            return (
                <>
                    <img className='avatar-24' src={data.pictureURL || require('Assets/images/user.png')} alt='img' />
                    <span>{data.name}</span>
                </>
            );
        } else {
            return <span className='channel'>{getIntegratedChannelName(data, platform, true)}</span>;
        }
    };
    return (
        <div>
            <AdminBox className='channel-box'>{handleConditionalrendering()}</AdminBox>
        </div>
    );
};
Index.propTypes = {
    data: PropTypes.object,
    title: PropTypes.string,
    platform: PropTypes.string,
};

export default Index;
