import { useMutation, useQueryClient } from 'react-query';
import { deleteData, getData, postData } from 'Services/apiCall';
import { modifyUser, updateDepartment } from 'Services/apiFunctions';
import { GET_PAGINATED_USERS } from 'Services/apiKeys';

export const useModifyUser = ({ onSuccess, onError }) => {
    const queryClient = useQueryClient();
    return useMutation(modifyUser, {
        onError: async (err, _newUserData, context) => {
            onError(err);
            if (context?.previousData) {
                await queryClient.setQueryData(GET_PAGINATED_USERS, context.previousData);
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(GET_PAGINATED_USERS);
            onSuccess();
        },
    });
};

export const useModifyOrganisation = ({ onSuccess, onError }) => {
    const queryClient = useQueryClient();
    return useMutation(updateDepartment, {
        onError: async (err, _newUserData, context) => {
            onError(err?.message);
            if (context?.previousData) {
                await queryClient.setQueryData(GET_PAGINATED_USERS, context.previousData);
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(GET_PAGINATED_USERS);
            onSuccess();
        },
    });
};

export const getTrutoConnection = async () => {
    try {
        const res = await getData('truto/connection');
        return res?.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getTrutoLinkToken = async () => {
    try {
        const { data } = await getData(`truto/linkAccount`);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const integrationConfig = async ({ apiData }) => {
    try {
        const res = await postData('truto/config', apiData);
        return res;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const disconnectIntegration = async () => {
    try {
        const { data } = await deleteData('truto/disconnect');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getIntegrationList = async () => {
    try {
        const { data } = await getData('truto/integrationList');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};
