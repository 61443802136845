import React, { useState } from 'react';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import PropTypes from 'prop-types';
import { getIntegratedChannelName } from 'utils/HelperFunctions';

const Index = ({ open, modalData, handleSave, onClose, dropdownData, selectedOption, platform }) => {
    const [selectedValue, setSelectedValue] = useState(selectedOption);
    const handleButtonDisabled = () => {
        return !dropdownData?.length || !selectedValue;
    };

    return (
        <div>
            <ConfirmModal
                open={open}
                data={modalData}
                onClose={onClose}
                onConfirm={() => handleSave(selectedValue)}
                height={'219px'}
                dropdownData={dropdownData}
                showDropDown
                handleDropdownSelection={(_index, value) => {
                    setSelectedValue({
                        name: value.channelName,
                        id: value?.channelID || value?.channelId,
                        groupName: value?.groupName,
                        groupId: value?.groupId,
                    });
                }}
                singleButton
                disableButton={handleButtonDisabled()}
                selectedDropdownOption={
                    selectedValue ? getIntegratedChannelName(selectedValue, platform, true) : selectedValue
                }
            />
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    handleSave: PropTypes.func,
    onClose: PropTypes.func,
    modalData: PropTypes.array,
    selectedOption: PropTypes.object,
    dropdownData: PropTypes.array,
    platform: PropTypes.string,
};
export default Index;
