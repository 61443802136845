import React, { useState } from 'react';
import { useQuery } from 'react-query';
import TitleBar from 'components/ReusableComponents/TitleBar';
import { Tab, Tabs } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import WorkspaceSettings from './WorkspaceSettings';
import ContactDetails from './ContactDetails';
import Auditlogs from './Auditlogs';
import DefaultCountrySettings from './DefaultCountrySettings';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { GET_GLOBAL_SETTINGS } from 'Services/apiKeys';
import { getGlobalSettings } from 'Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import './style.scss';
import queryString from 'query-string';
import PropTypes from 'prop-types';

const ADMIN_SETTINGS = {
    GENERAL: 'general',
    LOGS: 'logs',
};

const tabHandler = (locationData) => {
    if (typeof locationData.tab === 'string') {
        return locationData.tab;
    } else {
        return ADMIN_SETTINGS.GENERAL;
    }
};

const Index = ({ history }) => {
    const location = useLocation();
    const locationData = queryString.parse(location.search);
    const { SetSnackbar } = useToaster();
    const [data, setData] = useState();
    const [activeKey, setActiveKey] = useState(tabHandler(locationData));

    useQuery([GET_GLOBAL_SETTINGS], getGlobalSettings, {
        onSuccess: (settingsData) => setData(settingsData),
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    const onSelectTab = (value) => {
        history.push({
            search: `?tab=${value}`,
        });
        setActiveKey(value);
    };

    return (
        <div className='settings-container'>
            <TitleBar title='Admin Settings' />
            <div className='navigatortabs'>
                <Tabs activeKey={activeKey} onSelect={onSelectTab}>
                    <Tab eventKey={ADMIN_SETTINGS.GENERAL} title={`General`}>
                        <div className='mt-3'>
                            <WorkspaceSettings />
                            <DefaultCountrySettings data={data} />
                            <ContactDetails data={data} />
                        </div>
                    </Tab>
                    <Tab eventKey={ADMIN_SETTINGS.LOGS} title={'Audit logs'}>
                        {activeKey === ADMIN_SETTINGS.LOGS && (
                            <div className='mt-3'>
                                <Auditlogs history={history} />
                            </div>
                        )}
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

export default Index;

Index.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
};
