export const INTEGRATIONS_LIST = [
    {
        name: 'alexishr',
        title: 'AlexisHR',
        logo: 'https://frontsite-logos.truto.one/HRIS/alexishr.png',
        isActive: true,
    },
    {
        name: 'bamboohr',
        title: 'BambooHR',
        logo: 'https://frontsite-logos.truto.one/logo_bamboohr.svg',
        isActive: true,
    },
    {
        name: 'breathe',
        title: 'Breathe',
        logo: 'https://frontsite-logos.truto.one/HRIS/breathe.png',
        isActive: true,
    },
    {
        name: 'charlie',
        title: 'Charlie',
        logo: 'https://frontsite-logos.truto.one/HRIS/charlie.png',
        isActive: true,
    },
    {
        name: 'clayhr',
        title: 'ClayHR',
        logo: 'https://frontsite-logos.truto.one/HRIS/clayhr.png',
        isActive: true,
    },
    {
        name: 'darwinbox',
        title: 'DarwinBox',
        logo: 'https://frontsite-logos.truto.one/darwinbox.png',
        isActive: true,
    },
    {
        name: 'factorial',
        title: 'Factorial',
        logo: 'https://frontsite-logos.truto.one/HRIS/factorial.png',
        isActive: true,
    },
    {
        name: 'greythr',
        title: 'greytHR',
        logo: 'https://frontsite-logos.truto.one/HRIS/greythr.svg',
        isActive: true,
    },
    {
        name: 'gusto',
        title: 'Gusto',
        logo: 'https://frontsite-logos.truto.one/HRIS/gusto.png',
        isActive: true,
    },
    {
        name: 'hrcloud',
        title: 'HR Cloud',
        logo: 'https://frontsite-logos.truto.one/HRIS/hrcloud.png',
        isActive: true,
    },
    {
        name: 'hrpartner',
        title: 'HR Partner',
        logo: 'https://frontsite-logos.truto.one/HRIS/hrpartner.png',
        isActive: true,
    },
    {
        name: 'haileyhr',
        title: 'Hailey HR',
        logo: 'https://frontsite-logos.truto.one/haileyhr.svg',
        isActive: true,
    },
    {
        name: 'heavenhr',
        title: 'HeavenHR',
        logo: 'https://frontsite-logos.truto.one/HRIS%2Fheavenhr.png',
        isActive: true,
    },
    {
        name: 'hibob',
        title: 'HiBob',
        logo: 'https://frontsite-logos.truto.one/HRIS/hibob.png',
        isActive: true,
    },
    {
        name: 'humaans',
        title: 'Humaans',
        logo: 'https://frontsite-logos.truto.one/logo_humaans.svg',
        isActive: true,
    },
    {
        name: 'intellihr',
        title: 'IntelliHR',
        logo: 'https://frontsite-logos.truto.one/HRIS/intellihr.png',
        isActive: true,
    },
    {
        name: 'keka',
        title: 'Keka',
        logo: 'https://frontsite-logos.truto.one/logo_keka.svg',
        isActive: true,
    },
    {
        name: 'kenjo',
        title: 'Kenjo',
        logo: 'https://frontsite-logos.truto.one/HRIS%2Fkenjo.svg',
        isActive: true,
    },
    {
        name: 'namely',
        title: 'Namely',
        logo: 'https://frontsite-logos.truto.one/HRIS/namely.png',
        isActive: true,
    },
    {
        name: 'officient',
        title: 'Officient',
        logo: 'https://frontsite-logos.truto.one/HRIS%2Fofficient.svg',
        isActive: true,
    },
    {
        name: 'peoplehr',
        title: 'PeopleHR',
        logo: 'https://frontsite-logos.truto.one/HRIS/peoplehr.png',
        isActive: true,
    },
    {
        name: 'peoplestrong',
        title: 'Peoplestrong',
        logo: 'https://frontsite-logos.truto.one/peoplestrong.png',
        isActive: true,
    },
    {
        name: 'personio',
        title: 'Personio',
        logo: 'https://frontsite-logos.truto.one/HRIS/personio.png',
        isActive: true,
    },
    {
        name: 'razorpayxpayroll',
        title: 'RazorpayX Payroll',
        logo: 'https://frontsite-logos.truto.one/logo_razorpayxpayroll.png',
        isActive: true,
    },
    {
        name: 'repute',
        title: 'Repute',
        logo: 'https://frontsite-logos.truto.one/repute.png',
        isActive: true,
    },
    {
        name: 'rippling',
        title: 'Rippling',
        logo: 'https://frontsite-logos.truto.one/rippling.png',
        isActive: true,
    },
    {
        name: 'sesame',
        title: 'Sesame',
        logo: 'https://frontsite-logos.truto.one/HRIS/sesame.png',
        isActive: true,
    },
    {
        name: 'square',
        title: 'Square',
        logo: 'https://frontsite-logos.truto.one/HRIS/squarepayroll.png',
        isActive: true,
    },
    {
        name: 'zohopeople',
        title: 'Zoho People',
        logo: 'https://frontsite-logos.truto.one/CRM/logo_zoho.svg',
        isActive: true,
    },
    {
        name: 'zwayam',
        title: 'Zwayam',
        logo: 'https://frontsite-logos.truto.one/zwayam.png',
        isActive: true,
    },
];

export const DATA_FETCHED = [
    'Employee Name',
    'Email address (Primary)',
    'Date of Joining',
    'Date of Birth',
    'Date of termination',
    'Country of work',
    'Manager',
    'Department',
];
