import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ConnectionIcon } from 'Assets/images/connection-icon.svg';
import { ReactComponent as GreenTick } from 'Assets/images/green-tick.svg';
import EWButton from 'components/ReusableComponents/EWButton';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { INFO_POINTS } from '../constants';
import './style.scss';

const Index = ({ handleConnect, isLoading }) => {
    return (
        <div className='hrms-banner-container'>
            {isLoading ? (
                <div className='connection-loader'>
                    <CustomLoader />
                </div>
            ) : (
                <>
                    <div className='banner-left-section'>
                        <ConnectionIcon height='4rem' width='4rem' />
                        <div className='info-container'>
                            <h1 className='info-heading'>Sync with HRMS to automate adding users</h1>
                            <div className='points-container'>
                                {INFO_POINTS.map((point) => (
                                    <div className='points-item' key={point}>
                                        <GreenTick height='1.25rem' width='1.25rem' />
                                        <p>{point}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <EWButton buttonText='Connect HRMS' onClick={handleConnect} />
                </>
            )}
        </div>
    );
};

Index.propTypes = {
    handleConnect: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default Index;
