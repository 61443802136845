import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { debounce, find } from 'lodash';
import { useQuery } from 'react-query';
import { showSnackBarMessage, isEmptyObject } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { pulseQuestionTypes } from 'constants.js';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { BlueTag } from 'utils/Stylesheet/style';
import { getQuestionLibrary } from 'Services/apiFunctions';
import { GET_QUESTION_LIBRARY } from 'Services/apiKeys';
import Tooltip from 'components/Styles/Tooltip';
import ConditionalWrapper from 'components/ReusableComponents/ConditionalWrapper';
import { FixedSizeList as List } from 'react-window';

const LibraryQuestion = ({ question }) => {
    return (
        <ConditionalWrapper
            condition={question?.title?.length > 60}
            wrapper={(children) => <Tooltip title={question.title}>{children}</Tooltip>}
        >
            <p>
                {question?.title?.length > 60 && question.title.substring(0, 57) + '...'}
                {question?.title?.length <= 60 && question.title}
            </p>
        </ConditionalWrapper>
    );
};

const QuestionRow = ({ question, index, style, handleAddQuestionFromLibrary }) => {
    return (
        <div className='pulse-library-question-container' style={style}>
            <BlueTag>{question.category}</BlueTag>
            <button className='ew-btn tb-btn' onClick={() => handleAddQuestionFromLibrary(question, index)}>
                + Add
            </button>
            <LibraryQuestion question={question} />
        </div>
    );
};

const Library = ({
    questions,
    setQuestions,
    addedLibraryQuestion,
    setAddedLibraryQuestion,
    openQuestion,
    setOpenQuestion,
    scrollToAddQuestionButton,
}) => {
    const { SetSnackbar } = useToaster();
    const [search, setSearch] = useState('');
    const [categoryList, setCategoryList] = useState([{ label: 'All categories', value: '' }]);
    const [activeCategory, setActiveCategory] = useState({});
    const [questionList, setQuestionList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [page, setPage] = useState(1);

    const { isLoading, isFetching } = useQuery(
        [GET_QUESTION_LIBRARY, page, search, Object.keys(activeCategory).join()],
        getQuestionLibrary,
        {
            onSuccess: (questionData) => {
                if (page === 1) {
                    setQuestionList([]);
                }
                setHasMore(questionData.hasMore);
                setCategoryData(questionData);
                setQuestionList((previousList) => [...previousList, ...questionData.questions]);
            },
            onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            retry: 0,
        }
    );

    const setCategoryData = (questionData) => {
        if (questionData?.categories?.length > 0 && categoryList.length < 2) {
            setCategoryList([
                ...categoryList,
                ...questionData.categories.map((categoryItem) => ({
                    label: categoryItem,
                    value: categoryItem,
                })),
            ]);
        }
    };

    const handleSearch = useCallback(
        debounce(async (value) => {
            setPage(1);
            setSearch(value.trim());
        }, 300),
        []
    );

    const handleAddQuestionFromLibrary = (question, index) => {
        const tempQuestions = [...questions];
        if (openQuestion > -1 && tempQuestions.length > 0 && tempQuestions[openQuestion]) {
            tempQuestions[openQuestion].question.name =
                tempQuestions[openQuestion].question.name || 'Untitled question';
            if (tempQuestions[openQuestion].questionType === 'MCQ') {
                tempQuestions[openQuestion].options.forEach((option, optionIndex) => {
                    option.name = option.name || `Choice ${optionIndex + 1}`;
                });
            }
        }
        setQuestions([
            ...tempQuestions,
            {
                id: nanoid(),
                question: { name: question.title, error: false },
                questionType:
                    question.type === 'MULTIPLE_CHOICE' || question.type === 'SINGLE_CHOICE' ? 'MCQ' : question.type,
                questionLabel:
                    question.type === 'MULTIPLE_CHOICE' || question.type === 'SINGLE_CHOICE'
                        ? 'Multiple choice'
                        : find(pulseQuestionTypes, (type) => type.value === question.type).label,
                required: false,
                multipleSelection: question.type === 'MULTIPLE_CHOICE',
                options: question.options?.length
                    ? question.options.map((option) => ({ name: option, error: false }))
                    : [
                          { name: '', error: false },
                          { name: '', error: false },
                      ],
                libraryIndex: index,
            },
        ]);
        setOpenQuestion(tempQuestions.length);
        const tempNewAddedList = { ...addedLibraryQuestion };
        tempNewAddedList[index] = true;
        setAddedLibraryQuestion(tempNewAddedList);
        scrollToAddQuestionButton();
    };
    const handleCategoryFilter = (index) => {
        setPage(1);
        if (index === 0 && !isEmptyObject(activeCategory)) {
            setActiveCategory({});
            return;
        }
        if (activeCategory[categoryList[index].value]) {
            const tempActiveCategory = { ...activeCategory };
            delete tempActiveCategory[categoryList[index].value];
            setActiveCategory(tempActiveCategory);
            return;
        }
        if (index > 0) {
            const tempActiveCategory = { ...activeCategory };
            tempActiveCategory[categoryList[index].value] = true;
            setActiveCategory(tempActiveCategory);
        }
    };

    if (isLoading) {
        return null;
    }

    return (
        <div className='question-library-container'>
            <div className='pulse-library-heading'>
                <h3 className='header-3'>Library</h3>
                <CustomFilterDropdown
                    dropDownID='dropdown-library'
                    selectedName={
                        isEmptyObject(activeCategory)
                            ? 'All Categories'
                            : `Selected (${Object.keys(activeCategory).length})`
                    }
                    filterOptions={categoryList.map((category, index) => ({
                        ...category,
                        isChecked: (index === 0 && isEmptyObject(activeCategory)) || activeCategory[category.value],
                    }))}
                    optionsSelected
                    buttonStyleClass='width-137'
                    handleSelection={handleCategoryFilter}
                />
            </div>
            <CustomSearchBox
                placeholder='Search for questions'
                handleSearch={(event) => handleSearch(event.target.value)}
            />
            <div className='lq-questions-container'>
                <List
                    height={700}
                    itemCount={questionList.length}
                    itemSize={106}
                    width={'100%'}
                    onItemsRendered={({ visibleStopIndex }) => {
                        if (visibleStopIndex === questionList.length - 1 && hasMore && !isFetching) {
                            setPage(page + 1);
                        }
                    }}
                >
                    {({ index, style }) => (
                        <QuestionRow
                            index={index}
                            question={questionList[index]}
                            style={style}
                            handleAddQuestionFromLibrary={handleAddQuestionFromLibrary}
                        />
                    )}
                </List>
            </div>
        </div>
    );
};

LibraryQuestion.propTypes = {
    question: PropTypes.object,
};

QuestionRow.propTypes = {
    question: PropTypes.object,
    index: PropTypes.number,
    style: PropTypes.object,
    handleAddQuestionFromLibrary: PropTypes.func,
};

Library.propTypes = {
    questions: PropTypes.array,
    setQuestions: PropTypes.func,
    addedLibraryQuestion: PropTypes.object,
    setAddedLibraryQuestion: PropTypes.func,
    openQuestion: PropTypes.number,
    setOpenQuestion: PropTypes.func,
    scrollToAddQuestionButton: PropTypes.func,
};

export default Library;
