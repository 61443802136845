import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import ActionMenuItem from './ActionMenuItem';
import { getQuickActions } from './constants';
import './styles.scss';

const Index = ({ history, isAdmin }) => {
    const { platform, isSuggestionsFlowCompleted, isFeedbackFlowCompleted, suggestionBoxEnabled, feedbackEnabled } =
        useSelector(mapStatesToProps, shallowEqual);
    const QUICK_ACTIONS = getQuickActions({
        platform: platform.toLowerCase(),
        isAdmin,
        isSuggestionsFlowCompleted,
        isFeedbackFlowCompleted,
        suggestionBoxEnabled,
        feedbackEnabled,
    });

    return (
        <>
            {QUICK_ACTIONS.length > 0 && (
                <div className='action-menu-container'>
                    <p className='action-menu-text'>Other Actions</p>
                    <div className='action-menu-items'>
                        {QUICK_ACTIONS.map(({ label, route, Icon }) => (
                            <ActionMenuItem key={label} label={label} route={route} history={history} Icon={Icon} />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

const mapStatesToProps = ({ Workspace }) => ({
    platform: Workspace.platform,
    isSuggestionsFlowCompleted: Workspace.todo.isSuggestionBoxFlowCompleted,
    isFeedbackFlowCompleted: Workspace.todo.isFeedbackFlowCompleted,
    suggestionBoxEnabled: Workspace.suggestionBox.enabled,
    feedbackEnabled: Workspace.feedback.enabled,
});

Index.propTypes = {
    history: PropTypes.object,
    isAdmin: PropTypes.bool,
};

export default Index;
