import styled from 'styled-components';

export const Tag = styled.span`
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 60px;
    align-self: center;
    margin-left: 10px;
`;

export const PaymentTag = styled.span`
    color: #f7f7f7;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    border-radius: 60px;
    align-self: center;
    margin-left: 8px;
    letter-spacing: 0.6px;
`;

export const PurpleTag = styled(Tag)`
    color: #5d5cf5;
    background: #dfdefd;
`;

export const LightPurpleTag = styled(Tag)`
    color: #5d5cf5;
    background: #efeffe;
`;

export const VoiletTag = styled(Tag)`
    color: #6882dc;
    background: rgba(153, 151, 245, 0.1);
`;

export const DarkVoiletTag = styled(Tag)`
    color: #734392;
    background: rgba(93, 92, 245, 0.1);
`;

export const GreenTag = styled(Tag)`
    color: #1eb89c;
    background: rgba(30, 184, 156, 0.1);
`;

export const GreenPulseTag = styled(Tag)`
    color: #30af38;
    background: rgba(48, 175, 56, 0.1);
`;

export const GreenPointsTag = styled(Tag)`
    color: #21a41e;
    background: rgba(41, 186, 28, 0.16);
    border-radius: 70px;
`;

export const DarkGreenTag = styled(Tag)`
    color: #ffffff;
    background: #1eb89c;
`;

export const PaymentDarkGreenTag = styled(PaymentTag)`
    background: #1eb89c;
`;

export const PaymentRedTag = styled(PaymentTag)`
    background: #e84f48;
`;

export const PaymentYellowTag = styled(PaymentTag)`
    color: #535961;
    background: #ffdf8e;
`;

export const OrangeTag = styled(Tag)`
    color: #f17105;
    background: #ffe3cb;
`;

export const LightOrangeTag = styled(Tag)`
    color: #f17105;
    background: rgba(241, 113, 5, 0.1);
`;

export const DarkOrangeTag = styled(Tag)`
    color: #f48d37;
    background: rgba(244, 141, 55, 0.1);
`;

export const GrayTag = styled(Tag)`
    color: #8392a3;
    background: #cbd5e0;
`;

export const YellowTag = styled(Tag)`
    color: #ffba07;
    background: rgba(255, 186, 7, 0.15);
`;
export const YellowTagDark = styled(Tag)`
    color: #545454;
    background: #ffdf85;
    padding: 4px 21px;
`;
export const YellowTagDelayed = styled(Tag)`
    color: #d19700;
    background: #fff7e2;
`;
export const LightRedTag = styled(Tag)`
    color: #f48d37;
    background: rgba(244, 141, 55, 0.1);
`;

export const EmailTag = styled(Tag)`
    color: #0029ff;
    background: rgba(0, 41, 255, 0.03);
    font-size: 12px;
`;

export const InvalidEmailTag = styled(Tag)`
    color: #ff2200;
    background: rgba(0, 41, 255, 0.03);
    font-size: 0.75rem;
    text-decoration-line: underline;
`;

export const BlueTag = styled(Tag)`
    color: #5d5cf5;
    background-color: rgba(93, 92, 245, 0.1);
    margin-left: 0;
`;

export const KudosTag = styled(Tag)`
    color: #f17105;
    margin-left: 0;
    background: #ffe3cb;
    border-radius: 70px;
`;

export const ShoutoutTag = styled(Tag)`
    color: #5d5cf5;
    margin-left: 0;
    background: #dfdefd;
    border-radius: 70px;
`;

export const CustomTag = styled(Tag)`
    font-family: 'open sans', sans-serif;
    font-weight: ${(props) => props?.fontWeight || '400'};
    color: ${(props) => props?.color || '#f17105'};
    margin-left: ${(props) => props?.marginLeft || '0px'};
    background: ${(props) => props?.background || '#dfdefd'};
    border-radius: ${(props) => props?.radius || '70px'};
    padding: ${(props) => props?.padding || '0.25rem 0.5rem'};
`;

export const AdminBox = styled.div`
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 220px;
    max-width: 350px;
    height: 56px;
    padding: 1rem;
    border: 1px solid #e5e7ea;
    position: relative;
    padding-right: 32px;
    .avatar-24 {
        margin-right: 0.5rem;
    }
    span {
        color: #15222f;
        font-size: 0.875rem;
        line-height: 1.5em;
    }
    .cross-icon {
        visibility: hidden;
        color: #e84f48;
        background-color: rgba(232, 79, 72, 0.05);
        position: absolute;
        right: 12px;
        padding: 4px 6px;
        border-radius: 4px;
        cursor: pointer;
        svg {
            font-size: 1rem;
        }
    }
    &:hover {
        .cross-icon {
            visibility: visible;
        }
    }
`;

export const Ellipsis = styled.span`
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    height: fit-content;
    text-overflow: ellipsis;
    max-width: ${(props) => props?.maxWidth || '150px'};
`;

export const SettingsBox = styled.div`
    padding: 24px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #fff;
`;
