import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { userLogout } from 'utils/SessionUtils/sessionUtils';
import { shallowEqual, useSelector } from 'react-redux';
import { ReactComponent as SignoutIcon } from 'Assets/images/signout.svg';
import { ReactComponent as FAQIcon } from 'Assets/images/faq.svg';
import { ReactComponent as WalletIcon } from 'Assets/images/wallet-icon.svg';
import { Tooltip } from 'components';
import { useQuery } from 'react-query';
import { PLAN_STATUS } from 'components/Dashboard/Content/PaymentsV2/constants';
import { GET_INAPP_NOTIFICATIONS_COUNT } from 'Services/apiKeys';
import { getNotificationCount } from '../Content/Notifications/ApiFunctions';
import { PLATFORM, ROUTES } from 'constants.js';
import { CustomMenu, MenuList } from 'components/ReusableComponents/Menu';
import './style.scss';

const Index = ({ history }) => {
    const {
        user,
        workspace,
        workspaceWallet,
        subscription,
        workspace: { platform },
    } = useSelector(mapStateToProps, shallowEqual);
    const [logoutOpen, setLogoutOpen] = useState(false);
    const buttonRef = useRef(null);
    const toggleLogout = () => setLogoutOpen((logoutOpenValue) => !logoutOpenValue);

    const { data } = useQuery([GET_INAPP_NOTIFICATIONS_COUNT], getNotificationCount, {
        refetchOnWindowFocus: true,
        staleTime: 5 * 60 * 1000,
        enabled: platform === PLATFORM.WEB,
    });

    const isNotificationsPage = window.location.pathname === ROUTES.NOTIFICATIONS;

    const showIconSelectedState = (icon) => {
        switch (icon) {
            case 'payment':
                return window.location.pathname === ROUTES.PAYMENTS;
            case 'settings':
                return window.location.pathname === ROUTES.SETTINGS;
            case 'notifications':
                return window.location.pathname === ROUTES.NOTIFICATIONS;
            default:
                return false;
        }
    };

    return (
        <div className='dashboard-header'>
            <div className='header-left-container'>
                {workspace.workspaceProfilePhoto ? (
                    <img src={workspace.workspaceProfilePhoto} alt={workspace.workspaceName} />
                ) : (
                    <div className='workspace-profile-icon'>
                        {workspace?.workspaceName?.charAt(0)?.toUpperCase() || 'P'}
                    </div>
                )}
                <p className='workspace-name'>{workspace?.workspaceName}</p>
                <div className='subscription-status'>
                    {subscription?.status === PLAN_STATUS.TRIAL && (
                        <p className='subscription-status-trial'>ON TRIAL</p>
                    )}
                    {subscription?.status === PLAN_STATUS.CANCELLED && (
                        <p className='subscription-status-cancelled'>CANCELLED</p>
                    )}
                </div>
                <div>
                    {user.isAdmin && (
                        <div className='wallet-balance'>
                            <WalletIcon />
                            <div className='ml-2'>
                                <p className='wallet-balance-text'>Redemption Wallet</p>
                                <span className='wallet-balance-amount'>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: workspaceWallet?.currencySymbol,
                                        }}
                                    />
                                    {workspaceWallet?.balance?.toFixed(2).toLocaleString('en-US') || 0}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className='header-right-container'>
                <div className='tasks'>
                    <button className='tasks-button' onClick={() => history.push(ROUTES.TASKS)}>
                        Tasks
                    </button>
                    {user?.pendingTasks > 0 && <div className='no-of-tasks'>{user?.pendingTasks}</div>}
                </div>
                {platform === PLATFORM.WEB && (
                    <div className='notifications'>
                        <Tooltip title='Notifications'>
                            <button onClick={() => history.push(ROUTES.NOTIFICATIONS)}>
                                <div
                                    className={clsx({
                                        notifications: true,
                                        'notifications-window': showIconSelectedState('notifications'),
                                    })}
                                />
                            </button>
                        </Tooltip>
                        {data?.new > 0 && !isNotificationsPage && (
                            <div className='no-of-notifications'>{data?.new}</div>
                        )}
                    </div>
                )}
                {user.isAdmin && (
                    <>
                        <Tooltip title='Payments'>
                            <button onClick={() => history.push(ROUTES.PAYMENTS)}>
                                <div
                                    className={clsx({
                                        payment: true,
                                        'payment-window': showIconSelectedState('payment'),
                                    })}
                                />
                            </button>
                        </Tooltip>
                        <Tooltip title='Settings'>
                            <button onClick={() => history.push(ROUTES.SETTINGS)}>
                                <div
                                    className={clsx({
                                        settings: true,
                                        'settings-window': showIconSelectedState('settings'),
                                    })}
                                />
                            </button>
                        </Tooltip>
                    </>
                )}

                <button onClick={toggleLogout} ref={buttonRef}>
                    <div className='user-dropdown'>
                        <img
                            className='user-profile-pic'
                            src={user.userProfilePhoto || require('Assets/images/defaultUser.png')}
                            alt={user.userName}
                        />
                        <span>{user.userName}</span>
                        <KeyboardArrowDownIcon
                            style={{
                                transform: logoutOpen ? 'rotate(-180deg)' : '',
                                transition: 'transform 250ms ease',
                            }}
                        />
                        <UserDropDownRenderList
                            userId={user._id}
                            history={history}
                            logoutOpen={logoutOpen}
                            anchorEl={buttonRef.current}
                            onClose={() => setLogoutOpen(false)}
                        />
                    </div>
                </button>
            </div>
        </div>
    );
};

const UserDropDownRenderList = ({ userId, logoutOpen, history, anchorEl, onClose }) => {
    const LIST_SETTINGS = [
        {
            path: `profile/${userId}`,
            name: 'Profile',
            icon: 'profile',
        },
    ];

    return (
        <CustomMenu
            open={logoutOpen}
            onClose={onClose}
            anchorEl={anchorEl}
            className='user-dropdown-menu'
            offset={{ top: 8, left: 20 }}
        >
            <MenuList>
                {LIST_SETTINGS.map(({ name, path, icon }) => (
                    <button onClick={() => history.push(`/dashboard/${path}`)} key={name}>
                        <div className='user-dropdown-item'>
                            <img src={require(`Assets/images/${icon}.svg`)} alt={name} />
                            {name}
                        </div>
                    </button>
                ))}
                <a
                    href='https://support.springworks.in/portal/en/kb/engagewith'
                    target='_blank'
                    rel='noreferrer noopener'
                    className='faq'
                >
                    <div className='user-dropdown-item'>
                        <FAQIcon />
                        FAQ
                    </div>
                </a>

                <button onClick={userLogout}>
                    <div className='user-dropdown-item' style={{ color: '#ff5353' }}>
                        <SignoutIcon />
                        Logout
                    </div>
                </button>
            </MenuList>
        </CustomMenu>
    );
};

const mapStateToProps = ({ User, Workspace, Payments }) => ({
    user: User,
    workspace: Workspace,
    subscription: Payments.subscription,
    workspaceWallet: Payments.workspaceWallet || {},
});

Index.propTypes = {
    history: PropTypes.object,
};

UserDropDownRenderList.propTypes = {
    userId: PropTypes.string,
    logoutOpen: PropTypes.bool,
    history: PropTypes.object,
    anchorEl: PropTypes.object,
    onClose: PropTypes.func,
};

export default withRouter(Index);
