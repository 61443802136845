import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TasksWidget from './TasksWidget';
import RecognitionBalance from './RecognitionBalance';
import RedeemBalance from './RedeemBalance';
import ActionMenu from './ActionMenu';
import { updateUserProfile } from 'redux/user/actions';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useQuery } from 'react-query';
import { getPendingTasks } from '../apiFunctions';
import { GET_PENDING_TASKS } from 'Services/apiKeys';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useToaster } from 'Context/SnackbarContext';
import './styles.scss';

const Index = ({ history }) => {
    const [pendingTasks, setPendingTasks] = useState({});
    const { isAdmin } = useSelector(mapStateToProps, shallowEqual);
    const SetSnackbar = useToaster();
    const dispatch = useDispatch();

    useQuery([GET_PENDING_TASKS], getPendingTasks, {
        onSuccess: (data) => {
            const pendingTasks = data.data;
            setPendingTasks(pendingTasks);
            updateUserProfile(dispatch, { pendingTasks: pendingTasks.taskCount });
        },
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    return (
        <div className='action-panel-container'>
            {pendingTasks.taskCount > 0 && <TasksWidget history={history} values={pendingTasks.tasks} />}
            <RecognitionBalance />
            <RedeemBalance history={history} />
            <ActionMenu history={history} isAdmin={isAdmin} />
        </div>
    );
};

const mapStateToProps = ({ User }) => ({
    isAdmin: User.isAdmin,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
