/**
 * @fileoverview Custom Menu Component
 *
 * A flexible, portal-based dropdown menu solution that renders at the document root level.
 * Features include:
 * - Dynamic positioning with viewport awareness
 * - Automatic overflow handling
 * - Keyboard interactions (Escape to close)
 * - Click-outside detection
 * - Scroll behavior management
 *
 * @example
 * // Basic usage
 * const MyComponent = () => {
 *   const [isOpen, setIsOpen] = useState(false);
 *   const buttonRef = useRef(null);
 *
 *   return (
 *     <>
 *       <button ref={buttonRef} onClick={() => setIsOpen(!isOpen)}>
 *         Toggle Menu
 *       </button>
 *       <CustomMenu
 *         open={isOpen}
 *         anchorEl={buttonRef.current}
 *         onClose={() => setIsOpen(false)}
 *       >
 *         <MenuList>
 *           <button onClick={() => {}}>Menu Item 1</button>
 *           <button onClick={() => {}}>Menu Item 2</button>
 *         </MenuList>
 *       </CustomMenu>
 *     </>
 *   );
 * };
 *
 * @example
 * // Usage with custom positioning and offset
 * <CustomMenu
 *   open={isOpen}
 *   anchorEl={buttonRef.current}
 *   onClose={() => setIsOpen(false)}
 *   position={MENU_POSITIONS.BOTTOM_RIGHT}
 *   offset={{ top: 8, left: 12 }}
 *   className="custom-menu-class"
 * >
 *   <MenuList>
 *     {items.map(item => (
 *       <button key={item.id} onClick={item.action}>
 *         {item.label}
 *       </button>
 *     ))}
 *   </MenuList>
 * </CustomMenu>
 */

import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { DEFAULT_OFFSET, MENU_POSITIONS, DEFAULT_Z_INDEX, EVENT_KEYS } from './constants';
import './styles.scss';

/**
 * MenuList Component
 *
 * A container component that provides consistent padding and styling for menu items.
 * Should be used as a direct child of CustomMenu for proper styling.
 *
 * @component
 * @example
 * <MenuList>
 *   <button onClick={() => {}}>Option 1</button>
 *   <button onClick={() => {}}>Option 2</button>
 * </MenuList>
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Menu items to be rendered
 * @param {string} [props.className] - Additional CSS class for custom styling
 * @returns {React.ReactElement} Rendered MenuList component
 */
const MenuList = ({ children, className = '' }) => {
    return <div className={`menu-list ${className}`}>{children}</div>;
};

/**
 * CustomMenu Component
 *
 * A portal-based dropdown menu component that handles positioning and user interactions.
 * Features automatic viewport awareness and overflow protection.
 *
 * @component
 * @example
 * // Basic usage with required props
 * const [isOpen, setIsOpen] = useState(false);
 * const buttonRef = useRef(null);
 *
 * <CustomMenu
 *   open={isOpen}
 *   anchorEl={buttonRef.current}
 *   onClose={() => setIsOpen(false)}
 * >
 *   <MenuList>
 *     <button>Click me</button>
 *   </MenuList>
 * </CustomMenu>
 *
 * @param {Object} props - Component props
 * @param {boolean} props.open - Controls menu visibility
 * @param {HTMLElement} props.anchorEl - Element to which the menu is anchored
 * @param {Function} props.onClose - Callback function when menu should close
 * @param {React.ReactNode} props.children - Menu content
 * @param {string} [props.className] - Additional CSS class for custom styling
 * @param {Object} [props.offset] - Offset from anchor element { top: number, left: number }
 * @param {string} [props.position] - Menu position relative to anchor. One of: BOTTOM_LEFT, BOTTOM_RIGHT, TOP_LEFT, TOP_RIGHT
 * @param {number} [props.zIndex] - CSS z-index value
 * @returns {React.ReactPortal | null} Rendered menu portal or null if closed
 */
const CustomMenu = ({
    open,
    anchorEl,
    onClose,
    children,
    className,
    offset = DEFAULT_OFFSET,
    position = MENU_POSITIONS.BOTTOM_LEFT,
    zIndex = DEFAULT_Z_INDEX,
}) => {
    const menuRef = useRef(null);

    useEffect(() => {
        const handleScroll = (event) => {
            if (menuRef.current?.contains(event.target)) {
                return;
            }
            onClose();
        };

        const handleResize = () => {
            onClose();
        };

        const handleKeyDown = (event) => {
            if (EVENT_KEYS.includes(event.key)) {
                onClose();
            }
        };

        window.addEventListener('scroll', handleScroll, true);
        window.addEventListener('resize', handleResize);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('scroll', handleScroll, true);
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    useEffect(() => {
        if (open && menuRef.current) {
            adjustMenuPosition();
        }
    }, [open]);

    /**
     * Adjusts menu position to prevent viewport overflow
     * Called when menu opens and handles:
     * - Right overflow: Shifts menu left
     * - Left overflow: Shifts menu right
     * - Bottom overflow: Flips menu above anchor
     * @private
     */
    const adjustMenuPosition = () => {
        if (!menuRef.current || !anchorEl) return;

        const menuRect = menuRef.current.getBoundingClientRect();
        const anchorRect = anchorEl.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        const overflowRight = anchorRect.left + menuRect.width > viewportWidth;
        const overflowLeft = anchorRect.right - menuRect.width < 0;
        const overflowBottom = anchorRect.bottom + menuRect.height > viewportHeight;

        if (overflowRight) {
            menuRef.current.style.left = `${Math.max(0, viewportWidth - menuRect.width)}px`;
        }
        if (overflowLeft) {
            menuRef.current.style.left = '0px';
        }
        if (overflowBottom) {
            menuRef.current.style.top = `${anchorRect.top - menuRect.height}px`;
        }
    };

    if (!anchorEl || !open) return null;

    const rect = anchorEl.getBoundingClientRect();

    /**
     * Calculates initial menu position based on anchor element and specified position
     * @private
     * @returns {Object} CSS position properties
     */
    const getMenuPosition = () => {
        const positions = {
            [MENU_POSITIONS.BOTTOM_LEFT]: {
                top: rect.bottom + window.scrollY + offset.top,
                left: rect.left + window.scrollX + offset.left,
            },
            [MENU_POSITIONS.BOTTOM_RIGHT]: {
                top: rect.bottom + window.scrollY + offset.top,
                left: rect.right + window.scrollX - offset.left,
                transform: 'translateX(-100%)',
            },
            [MENU_POSITIONS.TOP_LEFT]: {
                top: rect.top + window.scrollY - offset.top,
                left: rect.left + window.scrollX + offset.left,
                transform: 'translateY(-100%)',
            },
            [MENU_POSITIONS.TOP_RIGHT]: {
                top: rect.top + window.scrollY - offset.top,
                left: rect.right + window.scrollX - offset.left,
                transform: 'translate(-100%, -100%)',
            },
        };

        let menuPosition = positions[position] || positions[MENU_POSITIONS.BOTTOM_LEFT];

        return {
            ...menuPosition,
            maxWidth: '90vw', // Prevent menu from being wider than viewport
        };
    };

    const style = {
        position: 'absolute',
        ...getMenuPosition(),
        zIndex,
    };

    return ReactDOM.createPortal(
        <ClickAwayListener onClickAway={onClose}>
            <div ref={menuRef} className={`custom-menu ${className}`} style={style}>
                {children}
            </div>
        </ClickAwayListener>,
        document.body
    );
};

CustomMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.instanceOf(Element),
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    offset: PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.number,
    }),
    position: PropTypes.oneOf(Object.values(MENU_POSITIONS)),
    zIndex: PropTypes.number,
};

MenuList.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export { CustomMenu, MenuList, MENU_POSITIONS };
