import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tab, Tabs } from 'react-bootstrap';
import { useCommonContext } from 'Context/CommonContext';
import { useToaster } from 'Context/SnackbarContext';
import CSVModal from '../CSVModal';
import WelcomeModal from './Components/WelcomeModal';
import PointsTable from './PointsTable';
import Department from './Components/Department';
import Country from './Components/Country';
import queryString from 'query-string';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import TitleBar from 'components/ReusableComponents/TitleBar';
import ConnectHRMSBanner from './Components/ConnectHRMSBanner';
import HRMSIntegrationModal from './Components/HRMSIntegrationModal';
import ConnectedHRMSBanner from './Components/ConnectedHRMSBanner';
import { shallowEqual, useSelector } from 'react-redux';
import { disconnectIntegration, getTrutoConnection, getTrutoLinkToken, integrationConfig } from './ApiHooks';
import { useMutation, useQuery } from 'react-query';
import authenticate from '@truto/truto-link-sdk';
import EWButton from 'components/ReusableComponents/EWButton';
import MarkAsManagerModal from 'components/Modals/MarkAsManagerModal';
import { ReactComponent as IconInviteUsers } from 'Assets/images/ic-invite-users.svg';
import { ReactComponent as IconBulkUpdate } from 'Assets/images/ic-bulk-update.svg';
import { ReactComponent as IconMapTeams } from 'Assets/images/ic-map-teams.svg';
import InviteUsersModal from './InviteUsers';
import { PLATFORM, TAB_KEYS } from 'constants.js';
import { GET_TRUTO_CONNECTION, GET_TRUTO_LINK_TOKEN } from 'Services/apiKeys';
import './style.scss';
import { getTimestamp, setTimestamp } from 'utils/SessionUtils/sessionUtils';

const Index = ({ history }) => {
    const { setManagerflow } = useCommonContext();
    const { SetSnackbar } = useToaster();
    const { todo, platform } = useSelector(mapStateToProps, shallowEqual);
    const [key, setKey] = useState(TAB_KEYS.USERS.eventKey);
    const [csvOpen, setCsvOpen] = useState(false);
    const [onboardingRedirection, setOnboardingRedirection] = useState(false);
    const [showMarkAsManagerModal, setShowMarkAsManagerModal] = useState(false);
    const [isConnectionEstablished, setIsConnectionEstablished] = useState(false);
    const [showInviteUsersModal, setShowInviteUsersModal] = useState(false);
    const [showHRMSIntegrationModal, setShowHRMSIntegrationModal] = useState(false);
    const [isIntegrationConnected, setIsIntegrationConnected] = useState(false);
    const [integrationData, setIntegrationData] = useState({});
    const [selectedProvider, setSelectedProvider] = useState('');
    const [isSyncInitiaited, setIsSyncInitiaited] = useState(false);
    const [isActivateUsersByDefault, setIsActivateUsersByDefault] = useState(true);
    const [isConnectionInitiated, setIsConnectionInitiated] = useState(false);
    const [isConnectionDisconnecting, setIsConnectionDisconnecting] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const query = queryString.parse(history.location.search);

    const {
        refetch: refetchTrutoConnection,
        isLoading,
        isFetching,
    } = useQuery([GET_TRUTO_CONNECTION], getTrutoConnection, {
        onSuccess: (data) => {
            if (isConnectionEstablished && data?.data?.provider === undefined) {
                setIsIntegrationConnected(true);
                setTimeout(() => {
                    refetchTrutoConnection();
                }, 1000);
            }

            if (data?.data?.provider !== undefined) {
                setIsIntegrationConnected(true);
                setIntegrationData(data?.data);
                setIsActivateUsersByDefault(data?.data?.enableUsersOnSync);
            }
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    const { mutateAsync: integrationConfigMutation } = useMutation(integrationConfig);

    const handleToggleSwitch = async (checked) => {
        try {
            setIsActivateUsersByDefault(checked);
            await integrationConfigMutation({ apiData: { enableUsersOnSync: checked } });
            showSnackBarMessage(SetSnackbar, 'success', 'Updated successfully');
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
            setIsActivateUsersByDefault(!checked);
        }
    };

    const handleIntegrationSync = async () => {
        try {
            setIsSyncInitiaited(true);
            const { data } = await integrationConfigMutation({ apiData: { sync: true } });
            if (data?.ok) {
                refetchTrutoConnection();
            }
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        } finally {
            setIsSyncInitiaited(false);
        }
    };

    const { refetch: refetchTrutoLinkToken } = useQuery([GET_TRUTO_LINK_TOKEN], getTrutoLinkToken, {
        onSuccess: (data) => {
            const options = {
                integration: selectedProvider,
                noBack: true,
                authForm: 'oauth2',
                skipRapidForm: true,
                iFrame: true,
            };

            setShowHRMSIntegrationModal(false);

            authenticate(data?.linkToken, options)
                .then((response) => {
                    if (response?.result === 'success') {
                        setIsConnectionEstablished(true);
                        setIsConnectionInitiated(false);
                        refetchTrutoConnection();
                    }
                })
                .catch(() => {
                    setIsConnectionInitiated(false);
                    showSnackBarMessage(SetSnackbar, 'error', 'Authentication failed. Please try again.');
                });
        },
        onError: (error) => {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
            setIsConnectionInitiated(false);
            setShowHRMSIntegrationModal(true);
        },
        enabled: false,
    });

    function handleIntegrationConnect(provider) {
        setIsConnectionInitiated(true);
        setSelectedProvider(provider);
        refetchTrutoLinkToken();
    }

    const { mutateAsync: disconnectIntegrationMutation } = useMutation(disconnectIntegration);

    const handleDisconnectIntegration = async () => {
        try {
            setIsConnectionDisconnecting(true);
            const { data } = await disconnectIntegrationMutation();
            showSnackBarMessage(SetSnackbar, 'success', data?.message || 'Integration disconnected successfully.');
            setShowDropDown(false);
            setIsIntegrationConnected(false);
            setIntegrationData({});
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        } finally {
            setIsConnectionDisconnecting(false);
        }
    };

    useEffect(() => {
        if (query.mapManager) {
            setTimeout(() => {
                setShowMarkAsManagerModal(true);
            }, 2000);
        } else {
            setTimeout(() => {
                const timeStamp = getTimestamp();
                if (
                    todo.isManagerFlowCompleted === false &&
                    (!timeStamp || Date.now() - timeStamp > 24 * 60 * 60 * 1000)
                ) {
                    setOnboardingRedirection(true);
                    setTimestamp(Date.now().toString());
                }
            }, 2000);
        }
        if (query.type) {
            setKey(query.type);
        }
        setManagerflow(!!todo.isManagerFlowCompleted);
    }, [todo?.isManagerFlowCompleted]);

    useEffect(() => {
        if (platform === PLATFORM.WEB && history.location.search.includes('modal=invite_users')) {
            setShowInviteUsersModal(true);
        }
    }, []);

    return (
        <div className='organization-container'>
            <div className='title-container'>
                <TitleBar title='Organization' />
                <div className='switch-container-team d-flex justify-content-end align-items-center'>
                    {platform === PLATFORM.WEB && (
                        <EWButton
                            plainTextButton
                            primary={false}
                            buttonStyleClass='user-button'
                            onClick={() => setShowInviteUsersModal(true)}
                        >
                            <IconInviteUsers />
                            Invite Users
                        </EWButton>
                    )}
                    <EWButton
                        plainTextButton
                        primary={false}
                        onClick={() => setShowMarkAsManagerModal(true)}
                        buttonStyleClass={clsx('user-button middle-user-btn', {
                            'middle-btn-border-left': platform === PLATFORM.WEB,
                        })}
                    >
                        <IconMapTeams />
                        Map teams
                    </EWButton>
                    <EWButton
                        plainTextButton
                        primary={false}
                        buttonStyleClass='user-button'
                        onClick={() => setCsvOpen(true)}
                    >
                        <IconBulkUpdate />
                        Bulk update Users
                    </EWButton>
                </div>
            </div>
            {isIntegrationConnected ? (
                <ConnectedHRMSBanner
                    integrationData={integrationData}
                    isActivateUsersByDefault={isActivateUsersByDefault}
                    handleToggleSwitch={handleToggleSwitch}
                    handleIntegrationSync={handleIntegrationSync}
                    isSyncInProgress={isSyncInitiaited}
                    handleDisconnectIntegration={handleDisconnectIntegration}
                    showDropDown={showDropDown}
                    setShowDropDown={setShowDropDown}
                    isConnectionEstablished={isConnectionEstablished}
                    isConnectionDisconnecting={isConnectionDisconnecting}
                />
            ) : (
                <ConnectHRMSBanner
                    handleConnect={() => setShowHRMSIntegrationModal(true)}
                    isLoading={isLoading || isFetching}
                />
            )}
            <div>
                <div
                    className={clsx('container-member-manager navigatortabs', {
                        'table-z-index': showDropDown,
                    })}
                >
                    <Tabs activeKey={key} onSelect={(_key) => setKey(_key)}>
                        <Tab eventKey={TAB_KEYS.USERS.eventKey} title={TAB_KEYS.USERS.title}>
                            {key === TAB_KEYS.USERS.eventKey && <PointsTable history={history} />}
                        </Tab>
                        <Tab eventKey={TAB_KEYS.DEPARTMENTS.eventKey} title={TAB_KEYS.DEPARTMENTS.eventKey}>
                            {key === TAB_KEYS.DEPARTMENTS.eventKey && <Department />}
                        </Tab>
                        <Tab eventKey={TAB_KEYS.COUNTRIES.eventKey} title={TAB_KEYS.COUNTRIES.eventKey}>
                            {key === TAB_KEYS.COUNTRIES.eventKey && <Country />}
                        </Tab>
                    </Tabs>
                </div>
                <CSVModal open={csvOpen} setOpen={setCsvOpen} platform={platform} />
                <WelcomeModal
                    open={onboardingRedirection}
                    setOpen={setOnboardingRedirection}
                    handleButtonClick={setCsvOpen}
                />
                {showInviteUsersModal && (
                    <InviteUsersModal open={showInviteUsersModal} onClose={() => setShowInviteUsersModal(false)} />
                )}
                {showMarkAsManagerModal && (
                    <MarkAsManagerModal
                        open={showMarkAsManagerModal}
                        onClose={() => setShowMarkAsManagerModal(false)}
                    />
                )}

                {showHRMSIntegrationModal && (
                    <HRMSIntegrationModal
                        open={showHRMSIntegrationModal}
                        onClose={() => setShowHRMSIntegrationModal(false)}
                        handleConnect={handleIntegrationConnect}
                        isLoading={isConnectionInitiated}
                        selectedProvider={selectedProvider}
                    />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    todo: state.Workspace.todo,
    platform: state.Workspace.platform,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
