import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';

const Index = ({ open, setOpen, handleDraft, handleExit, isDraftPulse }) => {
    const onClose = (isCancel) => {
        if (isCancel) {
            handleExit();
        }
        setOpen(false);
    };

    const data = {
        confirm: 'Save and exit',
        cancel: 'Exit',
        heading: 'Are you sure you want to exit?',
        subHeading: isDraftPulse
            ? 'The changes made to the draft will be saved.'
            : 'You will loose your data if you exit.\nSave your progress to resume later. It will be saved as a draft',
    };

    return (
        <ConfirmModal
            open={open}
            data={data}
            onClose={onClose}
            onConfirm={handleDraft}
            headingSeparator
            height={'196px'}
        />
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleDraft: PropTypes.func,
    handleExit: PropTypes.func,
    isDraftPulse: PropTypes.bool,
};

export default Index;
