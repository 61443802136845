import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/ReusableComponents/LaunchScreenHeader';
import { toggleFullscreen } from 'redux/layout/actions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { STEPPERS, BONUS_ROUTE, BONUS_STATUS, SEND_PRIVATELY } from '../constants';
import { GET_BONUS_DETAILS } from 'Services/apiKeys';
import { getBonusDetails } from '../Services/APIFunctions';
import BonusDetails from './BonusDetails';
import AnnouncementDetails from './AnnouncementDetails';
import BonusCreationsInfo from '../Components/BonusCreationInfo';
import { isValidBonus, useLaunchBonus, validateBonusDetails } from './LaunchBonusUtils';
import { getLaunchDate } from '../../EmployeeAwards/LaunchAward/LaunchAwardUtils';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { PLATFORM } from 'constants.js';

const Index = ({ history }) => {
    const dispatch = useDispatch();
    const { SetSnackbar } = useToaster();
    const { state } = useLocation();

    const editBonusId = state?.state?.edit ? state?.state?.id : null;
    const { platform, workspaceCurrency, workspaceCountry, userId } = useSelector(mapStateToProps, shallowEqual);

    const [step, setStep] = useState(0);
    const [bonusDetails, setBonusDetails] = useState({
        bonusName: '',
        awardee: [],
        message: '',
        bonusCurrency: workspaceCurrency,
        bonusAmount: 0,
        bonusPrize: [],
        companyValues: '',
        channelsData: [],
        announcement: { date: new Date(), time: 'Send instantly', channelId: '', channelName: '' },
        addAmountInMessage: true,
    });

    useEffect(() => {
        toggleFullscreen(dispatch, true);
    }, []);
    const handleStepChange = (stepValue) => setStep(stepValue);

    const handleNext = () => {
        if (step === 0) {
            if (validateBonusDetails({ bonusDetails, SetSnackbar, userId })) {
                setStep(1);
            }
        } else if (step === 1) {
            bonusPostHandler();
        }
    };

    const onSuccess = () => {
        showSnackBarMessage(SetSnackbar, 'success', `Bonus ${editBonusId ? 'updated' : 'created'} successfully`);
        toggleFullscreen(dispatch, false);
        history.push(
            bonusDetails.announcement.time === 'Send instantly'
                ? `${BONUS_ROUTE.HOME}?tab=${BONUS_STATUS.COMPLETED}`
                : BONUS_ROUTE.HOME
        );
    };

    const onError = (err) => showSnackBarMessage(SetSnackbar, 'error', err.message);
    const { mutate: launchBonus, isLoading } = useLaunchBonus({ onError, onSuccess, editBonusId });
    const addSecondsToCurrentDate = (seconds) => {
        let currentDate = new Date();
        currentDate.setSeconds(currentDate.getSeconds() + seconds);
        return currentDate;
    };

    const bonusPostHandler = () => {
        if (isValidBonus({ bonusDetails, SetSnackbar, platform, userId })) {
            if (bonusDetails.announcement.time === 'Send instantly') {
                bonusDetails.announcement.time = addSecondsToCurrentDate(15);
                bonusDetails.announcement.sendInstant = true;
            }
            const { launchDate } = getLaunchDate(
                bonusDetails.announcement.date,
                bonusDetails.announcement.time,
                bonusDetails.announcement.sendInstant
            );
            const apiData = {
                ...(editBonusId && { correlationId: editBonusId }),
                name: bonusDetails.bonusName || 'Bonus',
                defaultAmount: bonusDetails.bonusAmount,
                defaultCurrency: bonusDetails.bonusCurrency,
                country: bonusDetails.bonusPrize.country,
                bonusPrize: bonusDetails.bonusPrize,
                launchDate,
                ...(platform === PLATFORM.TEAMS && {
                    channelToAnnounce:
                        bonusDetails.announcement?.channelName === SEND_PRIVATELY
                            ? 'sendPrivately'
                            : {
                                  channelID: bonusDetails.announcement?.channelId,
                                  channelName: bonusDetails.announcement?.channelName,
                                  groupId: bonusDetails.announcement?.groupId,
                              },
                }),
                ...(platform === PLATFORM.SLACK && { channelToAnnounce: bonusDetails.announcement.channelId }),
                sendInstantly: bonusDetails.announcement.sendInstant || false,
                selectedUsers: bonusDetails.awardee.map((item) => item.id || item._id),
                message: bonusDetails.message,
                companyValueId: bonusDetails.companyValues.id,
                isAIEnhanced: false,
                addAmountInMessage: bonusDetails.addAmountInMessage,
            };
            launchBonus({ bonusData: apiData });
        }
    };

    const { isLoading: isLoadingBonusDetails } = useQuery([GET_BONUS_DETAILS, editBonusId], getBonusDetails, {
        enabled: !!editBonusId, // this will call the api ony when editAwardId is present
        onSuccess: (data) => {
            let bonusData = data.data;
            let awardeeDetails = bonusData.selectedUsers;
            const details = {
                bonusName: bonusData.name,
                awardee: awardeeDetails,
                message: bonusData.message,
                bonusPrize: bonusData.bonusPrize,
                companyValues: {
                    id: bonusData.companyValue?._id,
                    value: bonusData.companyValue?.name,
                },
                bonusAmount: bonusData.defaultAmount,
                announcement: {
                    date: new Date(bonusData.launchDate),
                    time: format(new Date(bonusData.launchDate), 'hh:mm a'),
                    channelId: bonusData.channel?.id,
                    channelName: bonusData.channel ? bonusData.channel.name : SEND_PRIVATELY,
                },
                addAmountInMessage: bonusData.addAmountInMessage,
            };
            setBonusDetails({ ...bonusDetails, ...details });
        },
        onError,
    });

    const onClose = () => {
        toggleFullscreen(dispatch, false);
        history.push(BONUS_ROUTE.HOME);
    };

    return (
        <div className='container-launch-award'>
            <Header
                step={step}
                handleNext={handleNext}
                onStepChange={handleStepChange}
                onClose={onClose}
                loading={isLoading}
                buttonText={editBonusId ? 'Update Bonus' : 'Award Bonus'}
                title={'Award Bonus'}
                steppers={STEPPERS}
            />
            {isLoadingBonusDetails ? (
                <CustomLoader />
            ) : (
                <div className='bonus-tab-content'>
                    {step === 0 && (
                        <BonusDetails
                            bonusDetails={bonusDetails}
                            setBonusDetails={setBonusDetails}
                            editBonusId={editBonusId}
                        />
                    )}
                    {step === 1 && (
                        <AnnouncementDetails
                            bonusDetails={bonusDetails}
                            announcement={bonusDetails.announcement}
                            setBonusDetails={setBonusDetails}
                            platform={platform}
                        />
                    )}
                    <BonusCreationsInfo
                        bonusDetails={bonusDetails}
                        step={step}
                        platform={platform}
                        workspaceCountry={workspaceCountry}
                    />
                </div>
            )}
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};
const mapStateToProps = ({ Workspace, User }) => ({
    workspaceCountry: Workspace.workspaceCountry,
    timezone: Workspace.timezone,
    platform: Workspace.platform,
    workspaceCurrency: Workspace.workspaceCurrency,
    userId: User._id,
});
export default Index;
