// Constants for keyboard event handling
const EVENT_KEYS = ['Escape'];

// Constants for menu positions
const MENU_POSITIONS = {
    BOTTOM_LEFT: 'bottom-left',
    BOTTOM_RIGHT: 'bottom-right',
    TOP_LEFT: 'top-left',
    TOP_RIGHT: 'top-right',
};

// Default z-index for the menu
const DEFAULT_Z_INDEX = 1000;

// Default offset values
const DEFAULT_OFFSET = { top: 8, left: 0 };

export { EVENT_KEYS, MENU_POSITIONS, DEFAULT_Z_INDEX, DEFAULT_OFFSET };
