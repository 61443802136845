import React from 'react';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';
import { format } from 'date-fns';
import { ClickAwayListener } from '@material-ui/core';
import { ReactComponent as GreenTick } from 'Assets/images/green-tick-hollow.svg';
import { ReactComponent as SyncIcon } from 'Assets/images/refresh.svg';
import { ReactComponent as DropdownDots } from 'Assets/images/dropdown-dots.svg';
import EWButton from 'components/ReusableComponents/EWButton';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import CustomSwitch from 'components/Styles/CustomSwitch';
import { INTEGRATIONS_LIST } from '../HRMSIntegrationModal/integrationsData';
import './style.scss';

const Index = ({
    integrationData,
    isActivateUsersByDefault,
    handleToggleSwitch,
    handleIntegrationSync,
    isSyncInProgress,
    handleDisconnectIntegration,
    showDropDown,
    setShowDropDown,
    isConnectionEstablished,
    isConnectionDisconnecting,
}) => {
    const provider = INTEGRATIONS_LIST.find((integration) => integration.name === integrationData?.provider);
    const lastSyncedAt = integrationData?.lastSyncedAt
        ? format(new Date(integrationData?.lastSyncedAt), 'p dd/MM/yyyy')
        : 'Not synced yet';
    const generateButtonText = () => (
        <span className='btn-text'>
            <SyncIcon className={clsx({ 'sync-icon': isSyncInProgress })} />
            <p>{isSyncInProgress || integrationData?.isSyncing ? 'Syncing' : 'Sync'}</p>
        </span>
    );

    return (
        <div className='hrms-connected-banner-container'>
            {isConnectionEstablished && !integrationData?.provider ? (
                <div className='connection-loader'>
                    <CustomLoader />
                </div>
            ) : (
                <>
                    <div className='integration-container'>
                        <img src={provider?.logo} alt='provider-logo' className='integration-logo' />
                        <div className='integration-info-container'>
                            <h1>Connected to {provider?.title}</h1>
                            <div className='integration-sync-details'>
                                <p>
                                    Last synced: <span className='last-sync-time'>{lastSyncedAt}</span>
                                </p>
                                <div className='users-added-container'>
                                    <GreenTick height='16px' width='16px' />
                                    <p>
                                        Added: <span className='users-added-value'>{integrationData?.usersAdded}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='cta-container'>
                        <EWButton
                            buttonText={generateButtonText()}
                            onClick={() => handleIntegrationSync()}
                            buttonStyleClass='resync-btn'
                            disabled={isSyncInProgress || integrationData?.isSyncing}
                        />
                        <button onClick={() => setShowDropDown(!showDropDown)} className='dropdown-btn'>
                            <DropdownDots />
                        </button>
                    </div>

                    {showDropDown && (
                        <ClickAwayListener onClickAway={() => setShowDropDown(false)}>
                            <div className='dropdown-container'>
                                <div className='activate-users-container'>
                                    <p>Activate all new users by default</p>
                                    <CustomSwitch
                                        checked={isActivateUsersByDefault}
                                        onClick={() => handleToggleSwitch(!isActivateUsersByDefault)}
                                    />
                                </div>

                                <button
                                    disabled={isConnectionDisconnecting}
                                    onClick={handleDisconnectIntegration}
                                    className={clsx('disconnect-container', {
                                        'disabled-disconnect': isConnectionDisconnecting,
                                    })}
                                >
                                    <p>Disconnect HRMS integration</p>
                                </button>
                            </div>
                        </ClickAwayListener>
                    )}
                </>
            )}
        </div>
    );
};

Index.propTypes = {
    integrationData: PropTypes.object.isRequired,
    isActivateUsersByDefault: PropTypes.bool.isRequired,
    handleToggleSwitch: PropTypes.func.isRequired,
    handleIntegrationSync: PropTypes.func.isRequired,
    isSyncInProgress: PropTypes.bool,
    handleDisconnectIntegration: PropTypes.func.isRequired,
    showDropDown: PropTypes.bool.isRequired,
    setShowDropDown: PropTypes.func.isRequired,
    isConnectionEstablished: PropTypes.bool.isRequired,
    isConnectionDisconnecting: PropTypes.bool.isRequired,
};

Index.defaultProps = {
    isSyncInProgress: false,
};

export default Index;
