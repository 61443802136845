import { useMutation } from 'react-query';
import { launchBonus, editLaunchedBonus } from '../Services/APIFunctions';
import { reduce } from 'lodash';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { PLATFORM } from 'constants.js';

export const useLaunchBonus = ({ onError, onSuccess, editBonusId }) => {
    return useMutation(editBonusId ? editLaunchedBonus : launchBonus, {
        onError: async (err) => onError(err),
        onSuccess: async () => onSuccess(),
    });
};

export const validateBonusDetails = ({ bonusDetails, SetSnackbar, userId }) => {
    const { awardee, message, bonusAmount } = bonusDetails;

    if (awardee.length === 0 || message === '' || bonusAmount === '') {
        showSnackBarMessage(SetSnackbar, 'error', 'Required fields are missing');
        return false;
    }
    if (bonusAmount === 0) {
        showSnackBarMessage(SetSnackbar, 'error', 'Invalid value; enter value greater than 0 to proceed.');
        return false;
    }
    if (bonusDetails.awardee.some((awardee) => !awardee.userCountry)) {
        showSnackBarMessage(SetSnackbar, 'error', 'Awardees must be assigned to a country');
        return false;
    }
    if (bonusDetails.awardee.some((awardee) => awardee._id === userId)) {
        showSnackBarMessage(SetSnackbar, 'error', 'You cannot award Bonus to yourself.');
        return false;
    }
    if (bonusDetails.message.trim().length < 10 && bonusDetails.message != '') {
        showSnackBarMessage(SetSnackbar, 'error', 'Message should be atleast 10 characters long');
        return false;
    }
    return true;
};

export const isValidBonus = ({ bonusDetails, SetSnackbar, platform, userId }) => {
    const { announcement } = bonusDetails;

    if (!validateBonusDetails({ bonusDetails, SetSnackbar, userId })) {
        return false;
    }

    if (
        !announcement.date ||
        !announcement.time ||
        (platform !== PLATFORM.WEB && (!announcement.channelId || !announcement.channelName))
    ) {
        showSnackBarMessage(SetSnackbar, 'error', 'Please enter all the announcement details');
        return false;
    }

    return true;
};

export const getRequestedListData = (users) =>
    reduce(
        users,
        ([channelData, userData], item) => {
            if (item.channel) {
                channelData.push({
                    channelId: item.channelID,
                    channelName: item.channelName,
                });
            } else {
                userData.push(item.id);
            }
            return [channelData, userData];
        },
        [[], []]
    );

export const getDefaultCountry = (workspaceCountry, countries) => {
    const filteredCountry = countries.filter((item) => item.countryCode === workspaceCountry);
    return {
        amount: '',
        currency: filteredCountry[0].currency,
        country: filteredCountry[0].country,
    };
};
