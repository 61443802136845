import React from 'react';
import { ReactComponent as ShowSuggestionBoxMessageIcon } from 'Assets/images/suggestion-box-icon.svg';

export const SUGGESTION = {
    HOME: '/dashboard/suggestion-box',
    SETUP_SUGGESTION_BOX: '/dashboard/suggestion-box/setup-suggestion-box',
    ALL_SUGGESTION: '/dashboard/suggestion-box/all-suggestions',
    CONFIGURATION: '/dashboard/suggestion-box/configuration',
};

export const SUGGESTION_BOX = 'Suggestion Box';
export const ALL_SUGGESTION = 'all-suggestion';
export const MY_SUGGESTION = 'my-suggestion';
export const SUGGESTION_BOX_CONFIGURATION = 'suggestion-box/configuration';

export const DEFAULT_SUGGESTION_BOX_SETTINGS = {
    enabled: false,
    admin: {},
    allowAnonymous: false,
    postPublicly: false,
    suggestionChannel: {},
    approvalRequired: false,
    allowVote: false,
};

export const SUGGESTION_TABLE_MENU_OPTIONS = [
    {
        label: (
            <span>
                <ShowSuggestionBoxMessageIcon className='mr-2 pointer-cursor' /> Show message
            </span>
        ),
        value: 'showMessage',
    },
];

export const getSuggestionBoxConfigs = (
    config,
    enabled = false,
    allowAnonymous = false,
    postPublicly = false,
    approvalRequired = false,
    allowVote = false
) => {
    return [
        {
            heading: 'Suggestion Box',
            subtext:
                'Enable Suggestion Box so that employees can give suggestions and feedback on organisation policies, senior management etc',
            isEnabled: enabled,
            key: 'enabled',
        },
        {
            heading: `Admin for suggestion box`,
            subtext:
                'Only the selected admin has permission to view and reply to private suggestions. Public suggestions can be replied to by anyone.',
            key: 'admin-permission',
            changeAdmin: true,
            admin: config?.admins[0],
        },
        {
            heading: `Allow anonymous suggestions`,
            subtext: 'If enabled, employees will be able to send suggestions anonymously',
            isEnabled: allowAnonymous,
            key: 'allowAnonymous',
        },
        {
            heading: `Post suggestions publicly`,
            subtext: 'If enabled, all public suggestions  will be posted in the selected channel',
            isEnabled: postPublicly,
            key: 'postPublicly',
            suggestionChannel: config?.suggestionChannel,
            approvalRequired: approvalRequired,
            allowVote: allowVote,
            changechannels: true,
            adminApproval: true,
        },
    ];
};

export const CONFIGURATION_KEYS = {
    POST_PUBLICLY: 'postPublicly',
    ALLOW_VOTE: 'allowVote',
    SUGGESTION_CHANNEL: 'suggestionChannel',
    ADMIN: 'admin',
    APPROVAL_REQUIRED: 'approvalRequired',
};

export const nonBooleankeys = ['approvalRequired', 'allowVote', 'suggestionChannel', 'admin'];

export const selectChannelModalData = {
    confirm: 'Save',
    heading: 'Channel for public suggestions',
    subHeading: 'Select a channel where public suggestions will be posted',
    title: 'channel',
};

export const changeAdminModalData = {
    confirm: 'Save',
    heading: 'Select a Suggestion Admin',
    subHeading: 'Only the selected admin has permission to view and reply on the suggestions received.',
    title: 'admin',
};

export const selectAdminModalData = {
    confirm: 'Enable',
    heading: 'Select a Suggestion Admin',
    subHeading: 'Only the selected admin has permission to view and reply on the suggestions received.',
    title: 'admin',
};
