import React from 'react';
import PropTypes from 'prop-types';
import CustomCheckbox from 'components/Styles/CustomCheckbox';
import CustomRadioButton from 'components/Styles/CustomRadioButton';
import { Tooltip } from 'components';
import ConditionalWrapper from 'components/ReusableComponents/ConditionalWrapper';
import clsx from 'clsx';

const toolTipWrapper = (children, filterLabel) => (
    <Tooltip white='true' title={filterLabel}>
        {children}
    </Tooltip>
);

const DropdownList = ({
    data,
    searchValue,
    singleSelect,
    showRadio,
    handleSelection,
    handleRadioSelection,
    onClose,
}) => {
    const handleOptionSelection = (item) => handleRadioSelection(item);
    const handleEvent = (index, filter) => {
        if (singleSelect) {
            onClose();
            setTimeout(() => {
                handleSelection(index, filter);
            }, 0);
        } else {
            handleSelection(index, filter);
        }
    };

    if (!data) return null;

    return (
        <div>
            {data.map((filter, index) => {
                const filterData = typeof filter === 'object' ? filter.value : filter;
                const filterLabel = typeof filter === 'object' ? filter.label : filter;
                const filterPicture = typeof filter === 'object' && filter?.pictureUrl;
                let hideFilter;
                if (searchValue) {
                    if (typeof filterLabel === 'string') {
                        hideFilter = !filterLabel.toLowerCase().includes(searchValue.toLowerCase());
                    }
                    // if filterLabel is an object, then it is a react component, like for case of country dropdown
                    else {
                        hideFilter = !filterData?.toLowerCase()?.includes(searchValue.toLowerCase());
                    }
                }
                const isSelected = filter.isChecked ? filter.isChecked : false;
                return (
                    <button
                        key={`${filterData}${index}`}
                        className={clsx({
                            'dropdown-item': true,
                            'display-none': hideFilter,
                            'padding-16': singleSelect,
                            'p-0': !singleSelect,
                            'dropdown-item-selected': filter?.isChecked,
                        })}
                        onClick={() => handleEvent(index, filter)}
                        data-testid='dropdown'
                    >
                        {showRadio && (
                            <div className='width-100'>
                                <CustomRadioButton
                                    checked={isSelected}
                                    onChange={() => handleOptionSelection(filter)}
                                    title={filter.name}
                                    name={`cr${index}`}
                                    size={24}
                                    bold={isSelected}
                                />
                            </div>
                        )}
                        {!singleSelect && (
                            <CustomCheckbox
                                checked={filter.isChecked ? filter.isChecked : false}
                                className='drop-down-check'
                                data-testid={'custom-checkbox'}
                            />
                        )}
                        {filterPicture ? (
                            <img
                                className='dropdown-list-user-image'
                                src={filterPicture || require('Assets/images/defaultUser.png')}
                                alt='user'
                            />
                        ) : null}
                        <ConditionalWrapper
                            condition={filterLabel?.length > 40}
                            wrapper={(children) => toolTipWrapper(children, filterLabel)}
                        >
                            <span className='dropdown-item-name'>
                                {filterLabel?.length > 40 ? filterLabel.slice(0, 37) + '...' : filterLabel}
                            </span>
                        </ConditionalWrapper>
                    </button>
                );
            })}
        </div>
    );
};

DropdownList.propTypes = {
    data: PropTypes.array,
    searchValue: PropTypes.string,
    singleSelect: PropTypes.bool,
    showRadio: PropTypes.bool,
    handleSelection: PropTypes.func,
    handleRadioSelection: PropTypes.func,
    onClose: PropTypes.func,
};

export default DropdownList;
